<i18n lang="yaml">
  en:
    review: Reviews
    contact: Contact
    installer: Pro installer ?
    blog: Blog
    buy: Order
    economy: Savings calculator
    economy-bis: Savings calculator
    compatibility: Test my compatibility
    faq: FAQ
    legal-notice: Legal notice
    terms-of-sales: Terms of sales
    social: Follow Ween on {name}
    private-data: Personal data
    privacy-policy: Privacy Policy
  fr:
    review: Témoignages
    contact: Contact
    installer: Professionnels
    blog: Blog
    buy: Commander
    economy: Estimer mes économies
    economy-bis: Économies
    compatibility: Tester ma compatibilité
    faq: FAQ
    legal-notice: Mentions légales
    terms-of-sales: Conditions générales de ventes
    social: Suivez Ween sur {name}
    private-data: Données personnelles
    privacy-policy: Politique de confidentialité
</i18n>

<template>
  <div v-scroll="handleScroll" class="layout">
    <header :class="[isFixed ? 'header-fixed' : '', 'layer-menu', 'layout__header']">
      <main-menu-mobile v-if="$mq.isOneOf('xxs xs')" :items="headerMenuMobileItems" />
      <main-menu-desktop v-else :items="headerMenuDesktopItems" />
    </header>

    <main class="layout__main">
      <nuxt />
    </main>

    <footer class="layout__footer || color-white || background-blue">
      <div class="page-center || grid">
        <div class="grid__row grid__row--center space-pt-7--xxs space-pt-10--s space-py-10--l">
          <div
            class="grid__col-12--xxs grid__col-2--m icon type-align-center type-align-left--m space-mb-6--xxs space-mb-0--m"
          >
            <nuxt-link :to="localePath('index')" class="display-inline-block">
              <icon name="logo-ween" width="128px" height="21px" />
            </nuxt-link>
          </div>
          <div class="grid__col-12--xxs grid__col-10--m">
            <ul class="flex flex-column--xxs flex-row--m align-center justify-end">
              <li
                v-for="(item, index) in footerMenuPrimary"
                v-show="typeof item.if !== 'undefined' ? item.if : true"
                :key="`footer-primary-item-${index}`"
                :class="{
                  'space-mt-3--xxs': index > 0,
                  'space-mt-0--m': index > 0,
                  'space-ml-5--m': index > 0,
                  'space-ml-7--l': index > 0,
                }"
              >
                <component :is="item.component" v-if="item.component" v-bind="item.props || {}" />
                <nuxt-link
                  v-else
                  :to="item.to"
                  :hash="item.hash"
                  :class="item.classes"
                  class="footer__menu-primary-link || type-btn type-weight-700"
                >{{ item.label }}</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="grid__row grid__row--center space-pt-5--xxs space-pb-10--xxs">
          <div class="grid__col-12--xxs grid__col-3--m space-mb-8--xxs space-mb-0--m">
            <div class="flex justify-center--xxs justify-start--m">
              <a
                v-for="(social, index) in socialLinks"
                :key="`footer-social-item-${index}`"
                :href="social.url"
                :title="$t('social', { name: social.name })"
                target="_blank"
                rel="nofollow,noopener,noreferrer"
                :class="[
                  'social-link',
                  'color-white',
                  index > 0 ? 'space-ml-3--xxs space-ml-5--m' : '',
                ]"
              >
                <span class="display-hidden-accessible">
                  {{
                  $t('social', { name: social.name })
                  }}
                </span>
                <icon
                  :name="`icon-${social.name}`.toLowerCase()"
                  width="32"
                  height="32"
                  circle="white"
                />
              </a>
            </div>
          </div>

          <div class="grid__col-12--xxs grid__col-9--m">
            <ul class="flex flex-column--xxs flex-row--m align-center justify-end">
              <li
                v-for="(item, index) in footerMenuSecondary"
                v-show="typeof item.if !== 'undefined' ? item.if : true"
                :key="`footer-primary-item-${index}`"
                :class="{
                  'space-mt-3--xxs': index > 0,
                  'space-mt-0--m': index > 0,
                  'space-ml-5--m': index > 0,
                  'space-ml-9--l': index > 0,
                }"
              >
                <component
                  :is="item.component"
                  v-if="item.component"
                  v-bind="item.props || {}"
                  v-on="item.on || {}"
                >{{ item.label }}</component>
                <nuxt-link
                  v-else
                  :to="item.to"
                  :hash="item.hash"
                  :class="item.classes"
                  class="footer__menu-secondary-link"
                >{{ item.label }}</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from 'vue';
import { socialLinks } from '~/config';
import MainMenuDesktop from '~/components/MainMenuDesktop';
import MainMenuMobile from '~/components/MainMenuMobile';
import LangSwitcher from '~/components/LangSwitcher';
import Icon from '~/components/Icon';

Vue.directive('scroll', {
  inserted(el, binding) {
    const f = function(evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  },
});

export default {
  components: {
    MainMenuDesktop,
    MainMenuMobile,
    LangSwitcher,
    Icon,
  },
  nuxtI18n: {
    seo: true,
  },

  data() {
    return {
      desktop: false,
      socialLinks,
      isFixed: false,
    };
  },
  computed: {
    headerMenuMobileItems() {
      return [
        {
          to: {
            path: this.localePath('index'),
            hash: '#review',
          },
          label: this.$t('review'),
        },
        {
          to: {
            path: this.localePath('compatibility'),
          },
          label: this.$t('compatibility'),
        },
        {
          to: {
            path: this.localePath('economy'),
          },
          label: this.$t('economy'),
        },
        this.$i18n.locale === 'fr' ?
          {
            to: {
              path: this.localePath('blog'),
            },
            label: this.$t('blog'),
          } : null,
        {
          to: {
            path: this.localePath('index-contact'),
          },
          label: this.$t('contact'),
        },
      ].filter(x => x !== null);
    },
    headerMenuDesktopItems() {
      return [
        {
          to: {
            path: this.localePath('index'),
            hash: '#review',
          },
          label: this.$t('review'),
        },
        this.$i18n.locale === 'fr' ?
          {
            to: {
              path: this.localePath('blog'),
            },
            label: this.$t('blog'),
          } : null,
        {
          to: {
            path: this.localePath('economy'),
          },
          label: this.$t('economy-bis'),
        },
        {
          to: {
            path: this.localePath('index-contact'),
          },
          label: this.$t('contact'),
        },
        {
          to: {
            path: this.localePath('index'),
            hash: '#offers',
          },
          label: this.$t('buy'),
          classes: 'btn btn--primary',
        },
        {
          label: 'lang-switcher',
          component: LangSwitcher,
        },
      ].filter(x => x !== null);
    },
    footerMenuPrimary() {
      return [
        {
          to: {
            path: this.localePath('compatibility'),
          },
          label: this.$t('compatibility'),
        },
        {
          to: {
            path: this.localePath('economy'),
          },
          label: this.$t('economy'),
        },

        {
          to: {
            path: this.localePath('faq'),
          },
          label: this.$t('faq'),
        },
        {
          to: {
            path: this.localePath('index-contact'),
          },
          label: this.$t('contact'),
        },
        {
          to: {
            path: this.localePath('index'),
            hash: '#offers',
          },
          label: this.$t('buy'),
          classes: 'btn btn--primary',
        },
        {
          label: 'lang-switcher',
          component: LangSwitcher,
          if: this.$mq.isOneOf('l xl xxl'),
        },
      ];
    },
    footerMenuSecondary() {
      return [
        {
          label: this.$t('private-data'),
          component: 'button',
          on: {
            click(e) {
              e.preventDefault();
              showAxeptioButton();

              if ({}.hasOwnProperty.call(window, 'axeptioSDK')) {
                window.axeptioSDK.openCookies();
              }
            },
          },
          props: {
            type: 'button',
            class: 'btn--no-appearance footer__menu-secondary-link',
          },
        },
        {
          to: this.localePath('legal-notice'),
          label: this.$t('legal-notice'),
        },
        {
          to: this.localePath('terms-of-sales'),
          label: this.$t('terms-of-sales'),
        },
        {
          to: this.localePath('privacy-policy'),
          label: this.$t('privacy-policy'),
        },
      ];
    },
  },
  methods: {
    handleScroll() {
      this.isFixed = window.scrollY > 72;
    },
  },
};
</script>

<style lang="scss">
.layer-menu {
  overflow: hidden;
}

.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 10px 60px 0 hsla(0, 0%, 78.4%, 0.2);
}

.header-attr {
  position: fixed;
}

.footer__menu-primary-link {
  &:not(.btn) {
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.5;
    }
  }
}

.social-link {
  .icon__inner {
    transition: opacity 0.2s ease;
  }

  &:hover {
    .icon__inner {
      opacity: 0.6;
    }
  }

  .icon {
    max-width: 40px;
    max-height: 40px;

    svg {
      max-width: 26px;
      max-height: 26px;
    }
  }
}

.footer__menu-secondary-link {
  font-size: 0.777777778em;
  color: inherit;
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
}
</style>
