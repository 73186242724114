<template>
  <nav class="main-menu-desktop || flex justify-center justify-end--s">
    <div class="page-center || grid">
      <div class="grid__row grid__row--center">
        <div class="grid__col-6--xxs grid__col-2--s flex align-center icon" @click="goUp">
          <nuxt-link :to="localePath('index')" class="display-inline-block">
            <icon name="logo-ween" width="128px" height="21px" classes="header__logo" />
          </nuxt-link>
        </div>
        <div class="grid__col-6--xxs grid__col-10--s">
          <ul class="main-menu__list flex flex-column flex-row--s align-center justify-end">
            <li
              v-for="(item, index) in items"
              v-show="typeof item.if !== 'undefined' ? item.if : true"
              :key="`main-menu-desktop-item-${index}`"
              class="main-menu__item"
              @click="gtm"
            >
              <div class="space-mb-4 space-mb-0--s space-ml-3--s space-ml-6--m">
                <component :is="item.component" v-if="item.component" v-bind="item.props || {}" />
                <nuxt-link
                  v-else
                  :to="item.to"
                  :hash="item.hash"
                  :class="item.classes"
                  class="main-menu__link || type-btn type-weight-700"
                >{{ item.label }}</nuxt-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Icon from '~/components/Icon';
import LangSwitcher from '~/components/LangSwitcher';

export default {
  components: {
    Icon,
    LangSwitcher,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    gtm() {
      this.$gtm.push({ event: 'gtm.header.click' });
    },
  },
};
</script>

<style lang="scss">
.main-menu-desktop {
  padding-top: 1em;
  padding-bottom: 1em;

  @media #{md('l')} {
    /* padding-top: 3.555555556em; */
    /* padding-bottom: 1.777777778em; */
  }
}

.main-menu__link {
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.6;
  }

  &.btn--primary {
    opacity: 1;
    transition: background-color 0.2s;
    box-shadow: unset;
  }

  &.nuxt-link-exact-active {
    font-weight: 700;
  }
}

.main-menu--secondary {
  .main-menu__link {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
